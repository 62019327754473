<template>
  <div class="first-page-header">
    <div class="tab-pc">
      <div
        class="tab-contain"
        :class="[
          tabItemIndex == 0 || tabItemIndex == 1
            ? 'PageColorClass'
            : 'NoPageColorClass',
        ]"
      >
        <div>
          <img
            style="width: 100px; margin-right: 30px"
            :src="
              tabItemIndex == 0 || tabItemIndex == 1
                ? require('../../assets/images/page1.png')
                : require('../../assets/images/page11.png')
            "
          />
          <div
            class="tab-item"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{
              tabItemActive: index == tabItemIndex,
              dispalyNo: !item.title,
            }"
            @click="handleTab(index, item.path)"
          >
            {{ item.title }}
            <div
              class="product-dialog2 animate__animated"
              v-scroll="'animate__fadeIn'"
            >
              <p
                :class="{ activep: tabChildIndex == childIndex }"
                v-for="(child, childIndex) in item.child"
                :key="childIndex"
                @click.stop="clickTabLisChild(childIndex, child.path)"
              >
                <span v-if="item.child.length != 0" class="triangle"></span>
                {{ child.title }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="phone">
            <img
              style="width: 12px; margin-right: 5px"
              src="../../assets/images/page2.png"
            />
            <span>020-39984322</span>
          </div>
          <el-button
            style="color: #24569c; border: 1px solid #24569c !important"
            @click="linkLogin(1)"
            >登录</el-button
          >
          <el-button
            style="background: #24569c; color: white"
            @click="linkLogin(2)"
            >立即注册</el-button
          >
        </div>
      </div>
    </div>

    <!-- <div class="product-dialog" v-if="tabItemIndex == 1">
      <div class="onebox">
        <p class="p1">云体系产品介绍汇总</p>
        <p class="p2">
          云体系产品涵盖了管理体系文件落地和检查等功能，更多......
        </p>
        <el-button @click="toProductPage">
          查看更多产品
          <i class="el-icon-d-arrow-right"></i>
        </el-button>
      </div>
      <div class="towbox">
        <div class="box" v-for="(item, index) in leftTabList" :key="index">
          <p class="p1">{{ item.title }}</p>
          <p
            class="p2"
            v-for="(childItem, childIndex) in item.children"
            :key="childIndex"
            @click="toPage(childItem.path, item.title, index, childIndex)"
          >
            {{ childItem.label }}
          </p>
        </div>
      </div>
      <i class="el-icon-close"></i>
    </div> -->
  </div>
</template>

<script>
import "animate.css";
export default {
  data() {
    return {
      tabItemIndex: 0,
      tabChildIndex: null,
      tabList: [
        {
          path: "/home-page",
          title: "首页",
        },
        {
          path: "",
          title: "产品介绍",
          child: [
            {
              path: "/system-build",
              title: "体系搭建",
            },
            {
              path: "/system-depute",
              title: "体系委托",
            },
            {
              path: "/system-to-ground",
              title: "体系落地",
            },
            {
              path: "/process-optimize",
              title: "流程优化",
            },
            {
              path: "",
              title: "考核标准",
            },
            {
              path: "",
              title: "数字化转型",
            },
            {
              path: "/que-bank-system",
              title: "题库体系",
            },
            {
              path: "",
              title: "数字模型服务",
            },
          ],
        },
        {
          path: "",
          // title: "产品优势",
        },
        {
          path: "/solution-index",
          // title: "解决方案",
        },
        {
          path: "",
          title: "合作客户",
        },
        {
          path: "/about-me",
          title: "关于我们",
        },
      ],
      leftTabList: [
        {
          title: "管理系统",
          children: [
            {
              label: "信息资产管理软件",
              path: "product-son-page26",
            },
            {
              label: "6S管理软件",
              path: "product-son-page27",
            },
            {
              label: "体系审核软件",
              path: "product-son-page28",
            },
            {
              label: "APQP项目管理软件",
              path: "product-son-page30",
            },
          ],
        },
        {
          title: "体系搭建",
          children: [
            {
              label: "构建体系树",
              path: "product-son-page33",
            },
            {
              label: "管理体系文件分类",
              path: "product-son-page46",
            },
            {
              label: "流程接口（包括部门接口）",
              path: "product-son-page11",
            },
            {
              label: "构建体系信息流",
              path: "product-son-page3",
            },
          ],
        },
        {
          title: "体系维护",
          children: [
            {
              label: "每年体系树的维护和更新",
              path: "product-son-page34",
            },
            {
              label: "每年保持各个岗位说明书与操作文件的一致性",
              path: "product-son-page35",
            },
            {
              label: "协助程序文件（二层文件）的更改",
              path: "product-son-page37",
            },
            {
              label: "每年管理手册维护和更新",
              path: "product-son-page38",
            },
          ],
        },
        {
          title: "体系落地",
          children: [
            {
              label: "构建个人工作看板",
              path: "product-son-page36",
            },

            {
              label: "构建工作标准",
              path: "product-son-page39",
            },
            {
              label: "构建体系落地检查题库",
              path: "product-son-page41",
            },
            {
              label: "ISO9001质量管理体系审核",
              path: "product-son-page17",
            },
          ],
        },
        {
          title: "培训课程",
          children: [],
        },
      ],
    };
  },
  watch: {
    $route(n) {
      if (n.fullPath == "/trial-use") {
        this.tabItemIndex = null;
      }
    },
  },
  directives: {
    scroll: {
      inserted(el, binding) {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const animationClass = binding.value;
                el.classList.add(animationClass);
                observer.unobserve(el);
              }
            });
          },
          {
            rootMargin: "0px",
            threshold: 0.1,
          }
        );
        observer.observe(el);
      },
    },
  },
  mounted() {
    this.handleTab(0, this.tabList[0].path);
  },
  methods: {
    handleTab(i, path) {
      this.tabItemIndex = i;
      if (i == 1) return;
      this.tabChildIndex = null;
      this.$router.push(path);
    },
    clickTabLisChild(i, p) {
      this.tabChildIndex = i;
      this.$router.push(p);
    },
    toProductPage() {
      this.$router.push("./product-view");
    },
    toPage(childPath, fatherTitle, index, childIndex) {
      this.$router.push(
        "./" +
          childPath +
          "?openList=" +
          fatherTitle +
          "&fatherIndex=" +
          index +
          "&childIndex=" +
          childIndex
      );
    },
    // 跳转说明书页面并打开新窗口
    linkLogin(index) {
      window.open(
        this.$router.resolve({
          path: index == 1 ? "/NewLogin" : "/NewLogin?showRegister=true",
        }).href,
        "_blank"
      );
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1200px) and (max-width: 1519px) {
  .first-page-header .tab-pc {
    width: 1500px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .first-page-header .tab-pc {
    width: 1200px !important;
  }
}

.first-page-header {
  .PageColorClass {
    background: #ffffff19;
    & > div {
      color: white;
    }
  }
  .NoPageColorClass {
    background: white;
    & > div {
      color: black;
    }
  }
  .tab-pc {
    width: 100%;
    position: flex;
    background-image: url("../../assets/images/page-background.png");
    .tab-contain {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      padding: 10px 7%;
      > div {
        position: relative;
        z-index: 999;
        display: flex;
        align-items: center;
        .tab-item:hover {
          .product-dialog2 {
            display: block !important;
          }
        }
        .tab-item {
          width: 80px;
          position: relative;
          padding: 5px 0;
          text-align: center;
          border-radius: 5px;
          .product-dialog2 {
            position: absolute;
            display: none;
            left: -20px;
            top: 40px;
            background: white;
            padding: 10px 0;
            border-radius: 5px;
            .triangle {
              position: absolute;
              width: 0px;
              height: 0px;
              left: 53px;
              top: -18px;
              border: 10px solid transparent;
              border-bottom-color: white;
            }
            p {
              color: black;
              width: 120px;
              padding: 5px 0;
              cursor: pointer;
            }
            .activep,
            p:hover {
              color: #24569c;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
        .tabItemActive {
          background-color: #24569c;
          color: white;
        }
        .dispalyNo {
          display: none;
        }
        .phone {
          display: flex;
          align-items: center;
          padding-right: 15px;
          width: 120px;
        }
        .el-button {
          width: 80px !important;
          height: 32px !important;
          border-radius: 5px !important;
          border: none !important;
        }
      }
    }
  }

  .product-dialog {
    position: absolute;
    width: 100%;
    background: white;
    display: flex;
    padding-bottom: 50px;
    z-index: 999;
  }
  .onebox {
    width: 17%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 30px;
    justify-content: center;
    .p1 {
      font-weight: bold;
      width: 130px;
      padding-top: 25px;
    }
    .p2 {
      width: 130px;
      font-size: 12px;
      color: #788296;
      word-break: break-all;
      margin: 20px 0;
    }
    > .el-button {
      margin-right: 20px;
      background: #24569c !important;
      color: white !important;
      border-radius: 5px !important;
    }
  }
  .towbox {
    display: flex;
    width: 70%;
    border-left: 1px solid #ebecec;
    .box {
      flex: 1;
      padding-top: 20px;
      margin-left: 30px;
      .p1 {
        font-weight: bold;
        padding-bottom: 15px;
        border-bottom: 1px solid #ebecec;
      }
      .p2 {
        margin-top: 15px;
        font-size: 12px;
        color: #5b5b5b;
      }
    }
  }
  .el-icon-close {
    margin-top: 20px;
    font-size: 18px;
    margin-left: 40px;
  }
}
</style>